import React from 'react'
import { bool } from 'prop-types'

class DisableBodyScroll extends React.PureComponent {
  componentDidMount () {
    if (typeof window !== 'undefined') {
      document.documentElement.classList.toggle('no-scroll', !this.props.isToScroll)
    }
  }
  componentDidUpdate () {
    if (typeof window !== 'undefined') {
      document.documentElement.classList.toggle('no-scroll', !this.props.isToScroll)
    }
  }
  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      document.documentElement.classList.remove('no-scroll')
    }
  }
  render () {
    return null
  }
}

DisableBodyScroll.defaultProps = {
  isToScroll: true
}
DisableBodyScroll.propTypes = {
  isToScroll: bool
}
export default DisableBodyScroll

import styled from 'styled-components'
import { white, easeOutExpo, MenuBg, darkBgColour } from '../../theme/'
// import { rem } from 'polished'
import { Flex, Box, Text } from 'rebass'
import IconSocialFacebook from '../../vectors/IconSocialFacebook'
import IconSocialTwitter from '../../vectors/IconSocialTwitter'
import IconSocialBandcamp from '../../vectors/IconSocialBandcamp'
import IconSocialInstagram from '../../vectors/IconSocialInstagram'
import BtnClose from '../../vectors/BtnClose'

export const MenuWrapper = styled(Flex)`
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: ${MenuBg};
  cursor: pointer;
  ${props => props.open ? 'transform: translateY(0);' : 'transform: translateY(calc(100% - 50px));'}
  transition: transform 0.4s ${easeOutExpo};
  &:hover {
    will-change: transform;
  }
`

export const Cta = styled(Text)`
  text-transform: uppercase;
  color: ${white.light};
  display: inline-block;
`

export const MenuButton = styled(Box)`
  position: relative;
`

export const MenuIcon = styled(Box)`
  position: relative;
  display: inline-block;
  width: 22px;
  height: 2px;
  background-color: #D8D8D8;
  transform: translateY(-450%);
  &:after, &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    background-color: #D8D8D8;
  }
  &:before {
    top: 5px;
    width: 17px;
  }
  &:after {
    top: 10px;
    width: 12px;
  }
`
export const Nav = styled(Box)`
  height: 100vh;
  width: 100vw;
  background: ${darkBgColour};
  z-index: 900;
`
export const NavLinks = styled(Box)`
    & > a {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    color: ${white.light};
    font-size: 16px;
    text-decoration: none;
    margin: 20px 0;
  }
`
export const Social = styled(Flex)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px rgba(151, 151, 151, 0.09) solid;
`
export const Facebook = styled(IconSocialFacebook)`
  position: relative;
  top: -3px;
  transform: scale(0.85);
  path {
    fill: ${props => props.invert ? 'white' : 'black'};
  }
`
export const Twitter = styled(IconSocialTwitter)`
  position: relative;
  top: -1px;
  transform: scale(0.85);
  path {
    fill: ${props => props.invert ? 'white' : 'black'};
  }
`
export const Instagram = styled(IconSocialInstagram)`
  position: relative;
  top: -3px;
  transform: scale(0.85);
  path {
    fill: ${props => props.invert ? 'white' : 'black'};
  }
`
export const Bandcamp = styled(IconSocialBandcamp)`
  position: relative;
  top: -3px;
  transform: scale(0.85);
  path {
    fill: ${props => props.invert ? 'white' : 'black'};
  }
`
export const Close = styled(BtnClose)`
  position: relative;
  top: 2px;
`

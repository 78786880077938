import React from 'react'
import styled, { css } from 'styled-components'

const width = '72'
const height = '22'
const viewBox = '0 0 72 22'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({noStyles}) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='#1A1919'
      fillRule='evenodd'
      key='key-0'
    >
      <path
        d='M64.266 8.006c-1.774 0-4.268.954-4.268 5.783 0 2.645 1.141 5.444 4.117 5.444 1.984 0 3.366-1.355 3.697-3.63h3.426C70.607 19.723 68.113 22 64.115 22c-4.869 0-7.544-3.536-7.544-8.211 0-4.798 2.555-8.55 7.665-8.55 3.606 0 6.673 1.845 7.002 5.751h-3.42c-.277-1.938-1.659-2.984-3.552-2.984zM42.363 13.78c0 3.142 1.736 5.501 4.747 5.501 3.405 0 4.682-2.78 4.682-5.5-.003-2.84-1.607-5.531-4.683-5.531-3.14 0-4.746 2.206-4.746 5.53zM42.493 0v7.978h.067C43.575 6.467 45.702 5.53 47.6 5.53c5.336 0 7.923 3.748 7.923 8.312 0 4.199-2.292 8.158-7.301 8.158-2.292 0-4.747-.512-5.86-2.568h-.065v2.147h-3.536V0h3.732zM25.709 22H0L12.006 0h25.708z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'IconSocialBandcamp'
})

import React from 'react'
import { StripeTop, StripeBottom, StripeHeading, StripeFilter, StripeFull, StripeRelease, StripeFullWide, StripeFullWideNoHeading } from './styles'
const Stripe = (props) => {
  let stripe = <StripeTop />
  if (props.bottom) {
    stripe = <StripeBottom />
  }
  if (props.heading) {
    stripe = <StripeHeading />
  }
  if (props.filter) {
    stripe = <StripeFilter />
  }
  if (props.full && !props.wide) {
    stripe = <StripeFull />
  }
  if (props.full && props.wide && props.noheading) {
    stripe = <StripeFullWideNoHeading />
  } else if (props.full && props.wide && !props.noheading) {
    stripe = <StripeFullWide />
  }
  if (props.release) {
    stripe = <StripeRelease />
  }
  return stripe
}

export default Stripe

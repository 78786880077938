import styled from 'styled-components'
import { media, darkestBgColour, borderColour, txtBodyColourContrast, easeOutExpo } from '../../theme/'
import { Flex, Box, Text } from 'rebass'

export const FlexWrapper = styled(Flex)`
  ${media.LargeScreenUp`
    position: ${props => props.sticky ? 'fixed' : 'static'};
  `};
  z-index: 100;
  bottom: 0;
  border-top: 1px ${borderColour} solid;
  background-color: ${darkestBgColour};
  padding: 17px 3% 16px;
  color: ${txtBodyColourContrast};
  text-align: left;
  
  a {
    color: ${txtBodyColourContrast};
    text-decoration: none;
    &:hover {
      color: white;
      opacity: 0.8;
    }
  }
`
export const ShowTitle = styled(Text)`
  font-weight: bold;
  letter-spacing: 1.78px;
  line-height: 1.3;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ShowDetails = styled(Text)`
  opacity: 0.8;
  letter-spacing: 0.6px;
`
export const Shows = styled(Box)`
  opacity: 0;
  transition: opacity 1s ${easeOutExpo};
  & span > a {
    display: block;
    width: 100%;
    padding-left: 10px;
    padding-right: 5%;
    ${media.LargeScreenUp`
      width: 50%;
      float: left;
    `};
    &:last-child {
      margin-right: 0;
    }
  }
  &.show {
    opacity: 1;
  }
`

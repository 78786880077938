import React from 'react'
import { InlineUnorderedList, Facebook, Instagram, Twitter, Bandcamp, Youtube, Tumblr } from './styles'

const FooterSocial = (props) => {
  return (
    <InlineUnorderedList invert={props.invert}>
      <li><a href={`${process.env.SOCIAL_BANDCAMP}`} target='_blank' rel='noopener noreferrer' aria-label='Bandcamp'><Bandcamp alt='follow us on Bandcamp' /></a></li>
      <li><a href={`${process.env.SOCIAL_FACEBOOK}`} target='_blank' rel='noopener noreferrer' aria-label='Facebook'><Facebook alt='Like us on Facebook' /></a></li>
      <li><a href={`${process.env.SOCIAL_TWITTER}`} target='_blank' rel='noopener noreferrer' aria-label='Twitter'><Twitter alt='follow us on Twitter' /></a></li>
      <li><a href={`${process.env.SOCIAL_INSTAGRAM}`} target='_blank' rel='noopener noreferrer' aria-label='Instagram'><Instagram alt='follow us on Instagram' /></a></li>
      <li><a href={`${process.env.SOCIAL_YOUTUBE}`} target='_blank' rel='noopener noreferrer' aria-label='Youtube'><Youtube alt='follow us on Youtube' /></a></li>
      <li><a href={`${process.env.SOCIAL_TUMBLR}`} target='_blank' rel='noopener noreferrer' aria-label='Tumblr'><Tumblr alt='follow us on Tumblr' /></a></li>
    </InlineUnorderedList>
  )
}
export default FooterSocial

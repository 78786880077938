import styled from 'styled-components'
import { media, easeOutExpo } from '../../theme/'
import IconSocialFacebook from '../../vectors/IconSocialFacebook'
import IconSocialInstagram from '../../vectors/IconSocialInstagram'
import IconSocialTwitter from '../../vectors/IconSocialTwitter'
import IconSocialYoutube from '../../vectors/IconSocialYoutube'
import IconSocialBandcamp from '../../vectors/IconSocialBandcamp'
import IconSocialTumblr from '../../vectors/IconSocialTumblr'
export const Facebook = styled(IconSocialFacebook)`
  position: relative;
  top: -1px;
  path {
    fill: ${props => props.invert ? 'white' : '#262626'};
  }
`
export const Instagram = styled(IconSocialInstagram)`
  path {
    fill: ${props => props.invert ? 'white' : '#262626'};
  }
`
export const Twitter = styled(IconSocialTwitter)`
  position: relative;
  top: -2px;
  path {
    fill: ${props => props.invert ? 'white' : '#262626'};
  }
`
export const Youtube = styled(IconSocialYoutube)`
  position: relative;
  top: 0px;
  path {
    fill: ${props => props.invert ? 'white' : '#262626'};
  }
`
export const Bandcamp = styled(IconSocialBandcamp)`
  position: relative;
  top: -3px;
  path {
    fill: ${props => props.invert ? 'white' : '#262626'};
  }
`
export const Tumblr = styled(IconSocialTumblr)`
  position: relative;
  top: -1px;
  path {
    fill: ${props => props.invert ? 'white' : '#262626'};
  }
`

export const InlineUnorderedList = styled.ul`
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  position: relative;
  z-index: 2;
  
  ${media.LargeScreenUp`
    text-align: right;
    transform: scale(0.8);
    transform-origin: right;
  `};
  svg {
    transition: opacity 0.3s ${easeOutExpo};
    &:hover {
      opacity: 0.6; 
    }
    path {
      fill: ${props => props.invert ? 'white' : '#262626'};
    }
  }
  > li {
    display: inline-block;
    margin: 0 auto;
    padding-left: 14%;
    padding-right: 4%;
    ${media.LargeScreenUp`
      padding-left: 5.85%;
      padding-right: 0;
    `};
    & a:after {
      display: none;
    }
  }
  > li:first-child {
    padding-left: 0;
  }
`

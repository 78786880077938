import styled from 'styled-components'
import { media, formSubmitTxtColour, easeOutExpo } from '../../theme/'
import { Text } from 'rebass'
import { rem } from 'polished'
import Form from 'react-formal'

export const SignUpButton = styled(Form.Button)`
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  border-radius: 0;
  width: 30px;
  height: 45px;
  position: absolute;
  right: 10px;
  top: -4px;
  background: none;
  border: 0;
`
export const SubscribeFieldset = styled.fieldset`
  border: none;
  position: relative;
  z-index: 0;
  padding: 0;
`
export const Success = styled.div`
  color: ${formSubmitTxtColour} !important;
  font-size: ${rem(13)};
  line-height: 1.5;
  max-width: 260px;
`
export const SubscribeInput = styled(Form.Field)`
  width: 100%;
  height: 45px;
  margin-top: -5px;
  color: white;
  font-size: ${rem(16)};
  padding: ${rem(20)} ${rem(60)} ${rem(20)} ${rem(20)};
  background: #303030;
  box-shadow: inset 0 0 3px 0 rgba(0,0,0,0.24);
  outline: none;
  border: 0;  
  border-bottom: 2px #3c3c3c solid;
  &::placeholder {
    color: white;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.5s ${easeOutExpo};
  }
  &:hover::placeholder {
    opacity: 0.8;
  }
`
export const SubscribeMessage = styled(Form.Message)`
  color: ${formSubmitTxtColour};
  display: block;
  font-size: ${rem(11)};
  position: absolute;
  left: 10px;
  bottom: ${rem(-25)};
  ${media.LargeScreenUp`
    bottom: ${rem(-15)};
  `};
  border-radius: 2px;
  background-color: #3c3c3c;
  padding: 2px 10px;
`
export const ErrorText = styled(Text)`
  color: red;
`

import React from 'react'
import { Wrapper, FooterSocialContactFlex, Facebook, Twitter, Bandcamp, ContactLink } from './styles'
import { Box } from 'rebass'

const FooterSocialContact = (props) => {
  return (
    <Wrapper pl={20} pr={30}>
      <FooterSocialContactFlex justifyContent='center' alignItems='center'>
        <Box flex='1 1 auto'><a href={`${process.env.SOCIAL_BANDCAMP}`} target='_blank' rel='noopener noreferrer'><Bandcamp invert={props.invert} alt='follow us on Bandcamp' /></a></Box>
        <Box flex='1 1 auto'><a href={`${process.env.SOCIAL_FACEBOOK}`} target='_blank' rel='noopener noreferrer'><Facebook invert={props.invert} alt='Like us on Facebook' /></a></Box>
        <Box flex='1 1 auto'><a href={`${process.env.SOCIAL_TWITTER}`} target='_blank' rel='noopener noreferrer'><Twitter invert={props.invert} alt='follow us on Twitter' /></a></Box>
        <Box flex='1 1 auto'><ContactLink to={`/contact/`} className='uppercase'>Contact</ContactLink></Box>
      </FooterSocialContactFlex>

    </Wrapper>

  )
}
export default FooterSocialContact

import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ children, to, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  // const isWindowAvailable = (typeof window !== 'undefined')
  // const url = (isWindowAvailable) ? new URL(item.url).pathname : ''
  const internal = /^\/(?!\/)/.test(to)

  // Use gatsby-link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={to} {...other}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  )
}

export default Link

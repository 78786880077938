import styled from 'styled-components'
import { easeOutExpo, darkestBgColour, borderColour } from '../../theme/'
import { Box, Flex } from 'rebass'
import { Link } from 'gatsby'
import IconSocialFacebook from '../../vectors/IconSocialFacebook'
import IconSocialTwitter from '../../vectors/IconSocialTwitter'
import IconSocialBandcamp from '../../vectors/IconSocialBandcamp'

export const Facebook = styled(IconSocialFacebook)`
  position: relative;
  top: -3px;
  transform: scale(0.85);
  path {
    fill: ${props => props.invert ? 'white' : 'black'};
  }
`
export const Twitter = styled(IconSocialTwitter)`
  position: relative;
  top: -2px;
  transform: scale(0.85);
  path {
    fill: ${props => props.invert ? 'white' : 'black'};
  }
`
export const Bandcamp = styled(IconSocialBandcamp)`
  position: relative;
  top: -3px;
  transform: scale(0.85);
  path {
    fill: ${props => props.invert ? 'white' : 'black'};
  }
`
export const Wrapper = styled(Box)`
  background-color: ${darkestBgColour};
  border-top: 1px ${borderColour} solid;
  a:after {
    outline: 1px blue solid;
    display: none;
  }
`
export const ContactLink = styled(Link)`
  display: block;
  position: relative;
  top: -5px;
`
export const FooterSocialContactFlex = styled(Flex)`
  padding: 20px 0 8px;
  font-size: 14px;
  svg {
    transition: opacity 0.3s ${easeOutExpo};
    &:hover {
      opacity: 0.6; 
    }
    path {
      fill: white;
    }
  }
  a {
    display: block;
    text-decoration: none;
    color: white;
    &:after {
      display: none;
    }
  }
  div:last-child {
    text-align: right;
  }
`

// import 'whatwg-fetch'
import React from 'react'
import * as yup from 'yup'
import Form from 'react-formal'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { SignUpButton, SubscribeFieldset, SubscribeInput, SubscribeMessage, ErrorText, Success } from './styles'
import BtnSubmit from '../../vectors/BtnSubmit'

const url = 'https://littlerocketrecords.us15.list-manage.com/subscribe/post?u=36da63a995d3aac48ff4f2701&id=5f5000cdf9'

const modelSchema = yup.object({
  EMAIL: yup
    .string()
    .default('')
    .required('Please enter an email address')
    .email('Please check your email address is correct')
    .max(255, 'Your email is over 255 characters')
})

class FooterSubscribe extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      submitSuccess: false,
      submitError: false,
      value: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (value) {
    this.setState({
      value: value,
      submitError: false,
      submitSuccess: false
    })
  }

  render () {
    return <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <Form
          schema={modelSchema}
          onSubmit={formData => subscribe(formData)}
        >
          { status !== 'success' && (
            <SubscribeFieldset>
              <SubscribeInput
                placeholder='Subscribe for tour dates & news'
                name='EMAIL'
                type='email'
                aria-label='Enter your email to subscribe to Little Rocket news'
                onChange={this.handleChange}
              />
              <SignUpButton type='submit' aria-label='Sign up!'>
                <BtnSubmit />
              </SignUpButton>
              <SubscribeMessage for='EMAIL' />
              { status === 'error' && <ErrorText>{this.props.header && <span>We&#x2019;ve encountered a problem. Please retry.</span>}</ErrorText> }
            </SubscribeFieldset>
          )}
          { status === 'success' && <Success><span>Almost finished...  Please confirm your email by clicking the link we've just sent you</span></Success> }
        </Form>
      )}
    />
  }
}

export default FooterSubscribe

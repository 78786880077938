import { injectGlobal } from 'styled-components'
import { rem, darken } from 'polished'
import assetCheckout from '../images/checkout.png'
import { media, easeOutExpo, typeface, white, red, darkestBgColour } from '../theme/'
injectGlobal`
  .snip-layout {
    background: url(${assetCheckout}) !important;
  }

  html, body {
    height: 100%;
    background-color: ${darkestBgColour};
  }
  body {
      margin: 0;
      font-family: ${typeface.sans};
      font-weight: normal;
      line-height: 1.8;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${typeface.serif};
    letter-spacing: ${rem(1.025)};
    line-height: 1;
    font-weight: 400;
  }

  input, textarea {
    font-family: ${typeface.sans} !important;
    font-weight: 400;
    -webkit-appearance: none;
    border-radius: 0;
  }

  .sans {
    font-family: ${typeface.sans};
  }
  .serif {
    font-family: ${typeface.serif};
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::selection {
      background: rgba(222, 0, 0, 0.7);
      color: white;
      text-shadow: none;
    }
  }

  a {
    transition: color 0.3s ${easeOutExpo};
    color: ${white.light};
  }

  a[href^="tel:"] {
    text-decoration: none;
  }

  path {
    transition: fill 0.3s ${easeOutExpo};
  }

  p {
    padding-bottom: 1.5em;
    letter-spacing: 0.7px;
    line-height: 1.8;
  }

  p, ul, ol {
    & a {
      text-decoration: none;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${red.mid};
        transition: opacity 0.3s ${easeOutExpo};
        opacity: 0.7;
      }
      &:hover:after {
        opacity: 1;
      }
    }
  }

  p > a:hover { 
    color: ${darken(0.13, white.light)};
  }

  .no-scroll {
    overflow: hidden;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .break {
    ${media.LargeScreenUp`
      display: block;
    `}
  }
`

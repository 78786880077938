import styled from 'styled-components'
import { media, darkestBgColour } from '../../theme/'
import { Flex, Box, Text } from 'rebass'

export const FooterWrapper = styled(Flex)`
  position: relative;
  z-index: 100;
  background-color: ${darkestBgColour};
`
export const FooterCopyright = styled(Text)`
  text-transform: uppercase;
  color: white;
  opacity: 0.24;
`
export const SubLinks = styled(Text)`
  float: left;
  position: relative;
  z-index: 51;
  width: 50%;
  text-align: right;
  margin-right: -35px;
  margin-top: 0px;
  a {
    padding-left: 30px;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
    text-decoration: none;
  }
  a:hover {
    color: red;
  }
`
export const FooterSocialFixed = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 2;
  ${media.LargeScreenUp`
    position: fixed;
    right: 55px;
    bottom: 105px;
    width: 50%;
  `};
`

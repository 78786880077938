import React from 'react'
import { Link } from 'gatsby'
import { Box } from 'rebass'
import DisableBodyScroll from '../DisableBodyScroll'
import { MenuWrapper, MenuButton, MenuIcon, Cta, Nav, NavLinks, Social, Facebook, Twitter, Bandcamp, Instagram, Close } from './styles'
class Menu extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: null
    }
    this.handleMenuToggle = this.handleMenuToggle.bind(this)
  }

  handleMenuToggle () {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  render () {
    return (
      <div>
        <MenuWrapper
          wrap w='100%' mx='auto' alignItems='center'
          pt={10} pb={12}
          open={this.state.isOpen}
        >
          <MenuButton
            ml='auto' px={30} pt={0} pb={12}
            onClick={this.handleMenuToggle}
          >
            <Cta fontSize={1} mr={15}>Menu</Cta>
            {
              this.state.isOpen ? <Close /> : <MenuIcon />
            }

          </MenuButton>
          <Nav is='nav' py={[20]}>
            <NavLinks px='10%'>
              {
                this.props.items.map((menuitem) => {
                  return <Link to={menuitem.url} key={`mi:${menuitem.title}`} onClick={this.handleMenuToggle}>{menuitem.title}</Link>
                })
              }
            </NavLinks>
            <Social px='10%' pt={23} pb={10}>
              <Box flex='1 1 auto'><a href={`${process.env.SOCIAL_BANDCAMP}`} target='_blank' rel='noopener noreferrer'><Bandcamp invert alt='follow us on Bandcamp' /></a></Box>
              <Box flex='1 1 auto'><a href={`${process.env.SOCIAL_FACEBOOK}`} target='_blank' rel='noopener noreferrer'><Facebook invert alt='Like us on Facebook' /></a></Box>
              <Box flex='1 1 auto'><a href={`${process.env.SOCIAL_TWITTER}`} target='_blank' rel='noopener noreferrer'><Twitter invert alt='follow us on Twitter' /></a></Box>
              <Box flex='1 1 auto'><a href={`${process.env.SOCIAL_INSTAGRAM}`} target='_blank' rel='noopener noreferrer'><Instagram invert alt='follow us on Instagram' /></a></Box>
            </Social>
          </Nav>

        </MenuWrapper>

        <DisableBodyScroll isToScroll={!this.state.isOpen} />
      </div>
    )
  }
}
export default Menu

import 'babel-polyfill'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { first } from 'lodash'
import { Provider } from 'rebass'
import { credits } from '../../util'
import Helmet from 'react-helmet'
import Menu from '../Menu'

import { overrides, SmallToMediumScreenOnly, txtBodyColour } from '../../theme'
import '../../layouts/global.style'

credits()
const Wrapper = styled.div`
  color: ${txtBodyColour};
  height: 100%;
`
const Body = styled.div`
  height: 100%;
`

export default ({ children, data }) => (
  <StaticQuery
    query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          allWordpressWpApiMenusMenusItems(
            filter: { name: { regex: "/Router/" } },
            sort: { fields: [name], order: DESC}
          ) {
            edges {
              node {
                id
                name
                items {
                  title
                  url
                  type
                }
              }
            }
          }
        }
      `}
    render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            htmlAttributes={{ lang: 'en' }}
            meta={[
              {
                name: 'description',
                content: 'We are an Independent DIY Record label. Predominantly in Punk Rock, Acoustic and with the odd twist here and there! If we love it we put it out.'
              },
              { property: 'og:title', content: 'Little Rocket Records' },
              { property: 'og:type', content: 'website' },
              { property: 'og:url', content: 'https://littlerocketrecords.co.uk' },
              {
                property: 'og:description',
                content: `We are an Independent DIY Record label. Predominantly in Punk Rock, Acoustic and with the odd twist here and there! If we love it we put it out.`
              },
              { property: 'og:image', content: 'https://littlerocketrecords.co.uk/og-image.png' },
              { property: 'twitter:card', content: 'summary_large_image' },
              { property: 'twitter:site', content: '@LittleRocketRec' },
              { property: 'twitter:creator', content: '@LittleRocketRec' }
            ]}
            link={[
              {
                rel: 'stylesheet',
                href: 'https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css',
                type: 'text/css',
                crossOrigin: 'anonymous'
              },
              {
                rel: 'preload',
                href: '/fonts/Misproject.woff2',
                as: 'font',
                crossOrigin: 'anonymous'
              },
              {
                rel: 'preload',
                href: '/fonts/Lato-Regular.woff2',
                as: 'font',
                crossOrigin: 'anonymous'
              },
              {
                rel: 'preload',
                href: '/fonts/Lato-Regular.woff2',
                as: 'font',
                crossOrigin: 'anonymous'
              },
              {
                rel: 'preload',
                href: '/fonts/Lato-Bold.woff2',
                as: 'font',
                crossOrigin: 'anonymous'
              },
              {
                rel: 'preload',
                href: '/fonts/Lato-Italic.woff2',
                as: 'font',
                crossOrigin: 'anonymous'
              }
            ]}
            style={[
              {
                cssText: `
            @font-face {
              font-family: 'Misproject';
              src: local('Misproject'),
                  url('/fonts/Misproject.woff2') format('woff2'),
                  url('/fonts/Misproject.woff') format('woff');
              font-weight: normal;
              font-style: normal;
              font-display: swap;
          }
          @font-face {
              font-family: 'Misproject';
              src: local('Misproject'),
                  url('/fonts/Misproject.woff2') format('woff2'),
                  url('/fonts/Misproject.woff') format('woff');
              font-weight: bold;
              font-style: normal;
              font-display: swap;
          }
          @font-face {
            font-family: 'Lato';
            src: local('Lato Regular'), local('Lato-Regular'),
                url('/fonts/Lato-Regular.woff2') format('woff2'),
                url('/fonts/Lato-Regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
              font-family: 'Lato';
              src: local('Lato Bold'), local('Lato-Bold'),
                  url('/fonts/Lato-Bold.woff2') format('woff2'),
                  url('/fonts/Lato-Bold.woff') format('woff');
              font-weight: bold;
              font-style: normal;
              font-display: swap;
          }

          @font-face {
              font-family: 'Lato';
              src: local('Lato Italic'), local('Lato-Italic'),
                  url('/fonts/Lato-Italic.woff2') format('woff2'),
                  url('/fonts/Lato-Italic.woff') format('woff');
              font-weight: normal;
              font-style: italic;
              font-display: swap;
          }
          `
              }
            ]}
          />
          <Provider theme={overrides.theme}>
            <ThemeProvider theme={{ mode: 'dark' }}>
              <Wrapper>

                <Body>
                  { children }
                </Body>

                <SmallToMediumScreenOnly>
                  <Menu items={first(data.allWordpressWpApiMenusMenusItems.edges).node.items} />
                </SmallToMediumScreenOnly>
              </Wrapper>

            </ThemeProvider>
          </Provider></>)}
  />)

import React from 'react'
import { Link } from 'gatsby'
import { Absolute } from 'rebass'
import { FlexWrapper, LittleRocket, Nav } from './styles'
const isWindowAvailable = (typeof window !== 'undefined')
const Router = (props) => {
  return (
    <FlexWrapper invert={props.invert} wrap w='100%' mx='auto' pt={40} pb={[30, null, 0]} alignItems='center' justifyContent='flex-end' >
      <Absolute top={[11, null, 32]} left={[20, null, '3.5%']}>
        <Link to='/'>
          <LittleRocket />
        </Link>
      </Absolute>

      <Nav is='nav' mr='3%' invert={props.invert}>
        {
          props.items.map((menuitem) => {
            const childroute = (isWindowAvailable && window.location.pathname.indexOf(menuitem.url) > -1) ? 'active' : null
            return <Link to={menuitem.url} className={childroute} activeClassName='active' key={`mi:${menuitem.title}`}>{menuitem.title}</Link>
          })
        }
      </Nav>
    </FlexWrapper>
  )
}

export default Router

import styled from 'styled-components'
import { media } from '../../theme/'
import { Text } from 'rebass'

export const PageTitleText = styled(Text)`
  color: ${props => (props.invert) ? 'black' : `white`};
  position: absolute;
  left: 6.2%;
  top: 0;
  letter-spacing: 0.08em;
  z-index: 1;
  ${media.LargeScreenUp`
    ${props => (props.isToHideOnDesktop) ? 'display: none;' : ``};
  `};
`

import React from 'react'
import { Box, Text, Link } from 'rebass'
import { FlexWrapper, Shows, ShowTitle, ShowDetails } from './styles'
import FooterSubscribe from '../FooterSubscribe'
import moment from 'moment'

const token = 'EAAFdW2egEUgBAHr9OJZAX7ROyZC6APchWbJdZAngt5EvWD34ZAiZCmaoZBoZBZCFSgJbUXqaWdNzFAJ3a2J1BYbsm4nOmN4g0uAoOZBC3r9bYo7Ek8B7L66ZAioMW8NGHEIyJXFAXanGAMMJIgWiocZAxEVddjA9sXKI0o2Ki6P2J0gJwZDZD'
const page = '715078772005705'
class UpcomingShows extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      shows: null
    }
  }

  componentDidMount () {
    fetch(`https://graph.facebook.com/v3.0/${page}/events/?access_token=${token}`)
      .then((resp) => resp.json()) // Transform the data into json
      .then(({ data }) => {
        if (data) {
          const mostRecentShows = data.slice(0, 2)
          this.setState({ shows: mostRecentShows })
        }
      })
  }

  render () {
    const shows = this.state.shows ? this.state.shows.map((show) => {
      const date = moment(show.start_time).format('MMM Do')
      const placeName = (show.place.name) ? `  ·  ${show.place.name}` : null
      const placeCity = (show.place.location) ? `  ·  ${show.place.location.city}` : null
      return <Link href={`https://www.facebook.com/events/${show.id}/`} key={`show:${show.id}`} target='_blank' rel='noopener noreferrer' py={[10, null, 0]}>
        <ShowTitle is='h4' f={2} mt={'4px'} mb={'5px'} className='sans'>{show.name}</ShowTitle>
        <ShowDetails f={0}>{date}{placeName}{placeCity}</ShowDetails>
      </Link>
    }) : null
    return (
      <FlexWrapper
        sticky={this.props.sticky}
        wrap alignItems='center'
        w='100%' mx='auto'
      >
        <Box w={[1 / 1, null, 2 / 20]} my={[20, null, 0]}>
          <Text is='h4' f={5} pl={10}><span className='break'>Upcoming</span> Shows</Text>
        </Box>
        <Shows w={[1 / 1, null, 13 / 20]} pb={[0, null, 0]} className={this.state.shows ? 'show' : 'hide'}>
          { this.state.shows && <span>{shows}</span>}
        </Shows>
        <Box w={[1 / 1, null, 5 / 20]} my={[30, null, 0]}>
          <FooterSubscribe />
        </Box>

      </FlexWrapper>
    )
  }
}

export default UpcomingShows

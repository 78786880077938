import React from 'react'
import styled, { css } from 'styled-components'

const width = '12'
const height = '21'
const viewBox = '0 0 12 21'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({noStyles}) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <path
      fill='#FFF'
      d='M11.979 19.029c-.667.63-2.27 1.099-3.58 1.12l-.147.001c-4.31 0-5.051-3.238-5.051-5.152v-6.16a.089.089 0 0 0-.089-.088H.639c-.05 0-.09-.04-.09-.088L.528 5.984l.057-.08C2.812 5.05 4.056 3.633 4.383 1.01c.019-.146.14-.15.141-.15h3.064c.05 0 .09.04.09.089v4.213c0 .05.04.089.09.089h3.38c.05 0 .09.04.09.087v3.326c0 .05-.04.089-.09.089H7.753c-.05 0-.086.039-.086.088v5.867c.02 1.258.635 1.896 1.835 1.896.484 0 .992-.11 1.498-.301a.088.088 0 0 1 .116.053L12 18.938l-.021.09z'
      key='key-0'
    />
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'IconSocialTumblr'
})

import React from 'react'
import { isUndefined } from 'lodash'
import { PageTitleText } from './styles'

const PageTitle = (props) => {
  const currentPageTitle = props.title || 'Records'
  const prefix = (props.prefix) ? 'Little Rocket ' : ''
  const isToHideOnDesktop = isUndefined(props.title)
  return (
    <PageTitleText
      is='h1' f={['26px', null, 7]}
      ml={[45, 25, 15]} mt={['23px', null, '22px']}
      pt={[0, null, 22]} pb={[0, null, 28]} px={[0, null, 30]}
      isToHideOnDesktop={isToHideOnDesktop}
      invert={props.invert}
    >
      {`${prefix} ${currentPageTitle}`}
    </PageTitleText>
  )
}

export default PageTitle

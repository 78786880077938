import React from 'react'
import { isUndefined } from 'lodash'
import { Box, Relative } from 'rebass'
// import { Link } from 'gatsby'
import { SmallToMediumScreenOnly, LargeScreenUp } from '../../theme/'
import { FooterWrapper, FooterSocialFixed, FooterCopyright } from './styles'
import FooterSocial from '../FooterSocial'
import FooterSocialContact from '../FooterSocialContact'
import UpcomingShows from '../UpcomingShows'

const Footer = (props) => {
  const currentYear = new Date().getFullYear()
  const isSticky = !isUndefined(props.sticky)
  const homeMargin = props.home ? 0 : '4px'
  return (
    <Relative pb={[50, null, 0]} ml={[homeMargin, null, 0]} zIndex={10}>
      <UpcomingShows sticky={isSticky} />

      { !isSticky &&
        <LargeScreenUp>
          <FooterWrapper wrap w='100%' mx='auto' py={30} px='3%' alignItems='center'>
            <Box w={[1 / 1, null, 1 / 3]} order={[2, null, 1]} f={1}>
              <FooterCopyright is='span' f={[0]}>&copy; {currentYear} all rights reserved</FooterCopyright>
            </Box>
            <Box w={[1 / 1, null, 2 / 3]} order={[1, null, 2]} mb={[40, null, 0]}>
              {/* <SubLinks>
                <Link to={'/our-story'}>About</Link>
                <Link to={'/contact'}>Contact</Link>
              </SubLinks> */}
              <FooterSocial invert />
            </Box>
          </FooterWrapper>
        </LargeScreenUp>
      }
      { isSticky &&
        <LargeScreenUp>
          <FooterSocialFixed>
            <FooterSocial invert={!props.invert} />
          </FooterSocialFixed>
        </LargeScreenUp>
      }
      <SmallToMediumScreenOnly>
        <FooterSocialContact />
      </SmallToMediumScreenOnly>
    </Relative>
  )
}

export default Footer

import styled from 'styled-components'
import { media, StyledLinks } from '../../theme/'
import { Flex, Box } from 'rebass'
import LogoLittleRocket from '../../vectors/LogoLittleRocket'

export const FlexWrapper = styled(Flex)`
  position: relative;
  z-index: 2;
  a {
    color: {props => props.invert ? 'black' : 'white'};
    text-decoration: none;
  }
`
export const Nav = styled(Box)`
  display: none;
  ${media.LargeScreenUp`
    display: block;
  `};
  
  ${StyledLinks}
  a {
    color: ${props => props.invert ? 'black' : 'white'};
    ${props => props.invert ? 'font-weight:normal;' : ''};
    ${props => props.invert ? 'text-shadow: none;' : ''};
  }
  
`
export const LittleRocket = styled(LogoLittleRocket)`
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.65);
  ${media.LargeScreenUp`
    transform: scale(1);
  `};
  &:hover {
    animation: shake 0.72s cubic-bezier(.36,.07,.19,.97) infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, -1px, 0);
    }
    20%, 80% {
      transform: translate3d(1px, 1px, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-1px, 1px, 0);
    }
    40%, 60% {
      transform: translate3d(1px, -1px, 0);
    }
  }
`
